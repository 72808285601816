import { render, staticRenderFns } from "./UserPlaceholders.vue?vue&type=template&id=5e066fbf&scoped=true&"
import script from "./UserPlaceholders.vue?vue&type=script&lang=js&"
export * from "./UserPlaceholders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e066fbf",
  null
  
)

export default component.exports